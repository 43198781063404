import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo-cse-shop-w.jpeg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { useSupabase } from "supabase";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-40 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "Boutique CSE Shop",
  submitButtonText = "Recevoir mon lien",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "tel:+262692529529",
}) => {

  const supabase = useSupabase();
  const [loading, setLoading] = React.useState(false);

  const handleLogin = async (e) => {
    localStorage.removeItem('user');
    setLoading(true);
    e.preventDefault();
    const email = e.target[0].value;
    if (!email) {
      setLoading(false);
      return alert('Veuillez saisir votre email.');
    }

    const data = await sendMagicLink(email);
    if (!data) {
      setLoading(false);
      return alert('Aucun salarié n\'a été trouvé avec cet email. Veuillez contacter le support sur shop.cse.reunion@gmail.com');
    }

    if (data) {
      setLoading(false);
      data.fields.EMAIL === email && alert('Un lien de connexion a été envoyé à votre adresse email.');
      localStorage.setItem('user', JSON.stringify(data));
      e.target[0].value = '';
    }
  };

  async function sendMagicLink(email) {
    const { data, error } = await supabase.functions.invoke('magic-link', {
      body: { email: email },
    })

    if (error) {
      console.error('Error sending magic link:', error.message)
      return alert('Une erreur est survenue lors de l\'envoi du lien. Veuillez contacter le support sur shop.cse.reunion@gmail.com')
    }

    return data
  }

  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  return <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              <Form onSubmit={handleLogin}>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email que le CSE a enregistré"
                  readOnly={loading}
                  defaultValue={user ? user.fields.EMAIL : ''}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                />
                <SubmitButton type="submit" disabled={loading}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{loading ? 'Chargement...' : submitButtonText}</span>
                </SubmitButton>
              </Form>
              <p tw="mt-6 text-xs text-gray-600 text-center">
                <span tw="border-b border-gray-500 border-dotted break-words">
                  Si vous ne connaissez pas votre email,<br /> veuillez contacter notre support<br /> au <a tw="text-primary-500" href={forgotPasswordUrl}>06 92 52 95 29</a> ou par
                  email à <a href="mailto:shop.cse.reunion@gmail.com" tw="text-primary-500">shop.cse.reunion@gmail.com</a>.
                </span>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
};
