// supabase client hook + context

import React from "react";
import { createClient } from '@supabase/supabase-js'

const SupabaseContext = React.createContext();

export const useSupabase = () => {
    const context = React.useContext(SupabaseContext);
    if (!context) {
        throw new Error("useSupabase must be used within a SupabaseProvider");
    }
    return context;
}

export const SupabaseProvider = ({ children }) => {
    const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY)
    return <SupabaseContext.Provider value={supabase}>{children}</SupabaseContext.Provider>;
}

export default SupabaseProvider;
