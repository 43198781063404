import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import TabCardGrid from "components/cards/TabCardGrid";
import { useParams } from "react-router-dom";
import { useSupabase } from "supabase";
import logo from "images/logo-cse-shop-w.jpeg";

const LogoLink = tw.a`flex items-center`;
const LogoImage = tw.img`h-40`;

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;

export default () => {
  const supabase = useSupabase();
  const { recordId } = useParams();

  const [loading, setLoading] = React.useState(false);
  const [offers, setOffers] = React.useState(null);

  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);

    async function fetchUser() {
      const { data, error } = await supabase.functions.invoke('fetch-employee', {
        body: { recordId: recordId },
      });
      if (error) {
        console.error('Error fetching employee:', error.message);
        setLoading(false);
        return alert('Une erreur est survenue lors de la récupération de vos informations. Veuillez contacter le support sur shop.cse.reunion@gmail.com');
      }
      localStorage.setItem('user', JSON.stringify(data));
      setUser(data);
      setLoading(false);
    }

    fetchUser();

    setLoading(true);

    async function fetchData() {
      const { data, error } = await supabase.functions.invoke('fetch-offers', {
        body: { recordId: recordId },
      });
      if (error) {
        console.error('Error fetching offers:', error.message);
        setLoading(false);
        return alert('Une erreur est survenue lors de la récupération des offres. Veuillez contacter le support sur shop.cse.reunion@gmail.com');
      }
      setOffers(data);
      setLoading(false);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={window.location.pathname}>
              <LogoImage src={logo} />
            </LogoLink>
            {loading || !user || !offers ? <p>Chargement...</p> : <TabCardGrid tabs={offers} employeeId={recordId} user={user} />}
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage >
  )
};
