import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { useSupabase } from "supabase";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-2 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/3 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;
const CardPriceSub = tw.p`mt-1 text-sm font-medium text-gray-600 line-through`;
const CardButtonMobile = styled(CardButton)`
  ${tw`hidden`}
  @media (max-width: 1023px) { // Afficher ce bouton pour les écrans inférieurs à 1024px
    ${tw`block mt-2`}
  }
`;
const Alert = tw.div`text-white font-medium bg-primary-500 p-4 rounded-lg mt-4`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  employeeId,
  heading,
  user,
  tabs = {}
}) => {
  const supabase = useSupabase();

  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(null);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (tabsKeys.length > 0 && activeTab === null) {
      setActiveTab(tabsKeys[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsKeys]);

  async function pushPreOrder(offerId) {
    let qt = Number(window.prompt('Combien de produits souhaitez-vous précommander ?', 1));

    while (qt === 0 || qt < 1) {
      if (qt === 0) {
        break;
      }
      alert('Veuillez entrer un nombre supérieur ou égal à 1.');
      qt = Number(window.prompt('Combien de produits souhaitez-vous précommander ?', 1));
    }

    setLoading(true)
    const { data, error } = await supabase.functions.invoke('push-preorder', {
      body: { employeeId: employeeId, offerId: offerId, qt: qt },
    })

    if (error) {
      setLoading(false)
      console.error('Error sending magic link:', error.message)
      return alert('Une erreur est survenue lors de la précommande. Veuillez contacter le support sur shop.cse.reunion@gmail.com')
    }
    setLoading(false)

    alert(`Votre précommande a bien été enregistrée, vous recevrez sur votre email un lien de paiement.`)

    return data
  }

  return (
    user && Object.keys(tabs).length > 0 ? <Container>
      <HeaderRow>
        <Header>{heading}</Header>
        <TabsControl>
          {Object.keys(tabs).map((tabName, index) => (
            <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
              {tabName}
            </TabControl>
          ))}
        </TabsControl>
      </HeaderRow>

      <Alert>
        Le bouton <strong>"Précommander"</strong> envoie un lien de paiement à <strong>{user ? user.fields.EMAIL : 'votre adresse email'}</strong>. Enfin, pour finaliser votre commande, cliquez sur le lien reçu par email.
      </Alert>

      {tabsKeys.map((tabKey, index) => (
        <TabContent
          key={index}
          variants={{
            current: {
              opacity: 1,
              scale: 1,
              display: "flex",
            },
            hidden: {
              opacity: 0,
              scale: 0.8,
              display: "none",
            }
          }}
          transition={{ duration: 0.4 }}
          initial={activeTab === tabKey ? "current" : "hidden"}
          animate={activeTab === tabKey ? "current" : "hidden"}
        >
          {tabs[tabKey].map((card, index) => (
            <CardContainer key={index}>
              <Card
                className="group"
                initial="rest"
                whileHover="hover"
                animate="rest"
                disabled={loading}
              >
                <CardImageContainer imageSrc={card.imageSrc}>
                  <CardHoverOverlay
                    variants={{
                      hover: {
                        opacity: 1,
                        height: "auto"
                      },
                      rest: {
                        opacity: 0,
                        height: 0
                      }
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <CardButton onClick={() => pushPreOrder(card.url)} disabled={loading}>{loading ? 'En cours...' : 'Précommander'}</CardButton>
                  </CardHoverOverlay>
                </CardImageContainer>
                <CardText>
                  <CardTitle>{card.title}</CardTitle>
                  <CardContent>{card.content}</CardContent>
                  <CardPrice>{card.price}</CardPrice>
                  <CardPriceSub>{card.pricePubic}</CardPriceSub>
                  <CardButtonMobile onClick={() => pushPreOrder(card.url)} disabled={loading}>{loading ? 'En cours...' : 'Précommander'}</CardButtonMobile>
                </CardText>
              </Card>
            </CardContainer>
          ))}
        </TabContent>
      ))}

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container> : <></>
  );
};
